import { type TableData } from '../components/AutoneTable';

import {
  type ExplorerPerformanceData,
  type RetailPerformanceData,
  type SelectedVariance,
  type ValueVolume,
} from './index';

export const isExplorerData = (
  item: TableData,
): item is ExplorerPerformanceData => {
  return 'primary_sales_vol' in item;
};

export const isRetailData = (
  item: TableData,
): item is RetailPerformanceData => {
  return 'Sales.totalSalesValue' in item;
};

export const isSelectedVariance = (id: any): id is SelectedVariance => {
  return [
    'this-year',
    'last-year',
    'last-last-year',
    'last-last-last-year',
    'last-last-last-last-year',
  ].includes(id);
};

export const isValueVolume = (id: any): id is ValueVolume => {
  return ['value', 'volume'].includes(id);
};
