import { type FilterOutput } from '../../types/filters';

export const combineProductIdFilterResults = (filterData: FilterOutput[]) => {
  const productSales = filterData?.find(
    (item) => item?.key === 'Product.product_id_sales',
  );

  const productInventory = filterData?.find(
    (item) => item?.key === 'Product.product_id_inventory',
  );

  const productSalesOptions =
    productSales?.options &&
    productSales?.options.map((item) => [item.id, item]);

  const productInventoryOptions =
    productInventory?.options &&
    productInventory?.options.map((item) => [item.id, item]);

  const productSalesDict = productSalesOptions
    ? Object.fromEntries(productSalesOptions)
    : undefined;

  const productInventoryDict = productInventoryOptions
    ? Object.fromEntries(productInventoryOptions)
    : undefined;

  const uniqueProducts = [
    ...new Set([
      ...Object.keys(productSalesDict),
      ...Object.keys(productInventoryDict),
    ]),
  ];

  const combinedData = uniqueProducts?.map((productID) => ({
    ...productSalesDict?.[productID],
    ...productInventoryDict?.[productID],
  }));

  return filterData
    .filter((item) => item.dimension !== 'Product.product_id')
    .concat({
      dimension: 'Product.product_id',
      title: 'Product Id',
      operator: 'equals',
      renderInSidebar: false,
      options: combinedData,
    });
};
