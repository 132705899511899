import { Button, Link, Stack, Typography, useTheme } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { type SetStateAction, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  handleMultipleSelections,
  handleSingleSelections,
} from '../../functions/actions';
import { type Option } from '../../types/filters';

const NUMBER_OF_RESULTS_LIMIT = 10;
const ACTIVE_PAGE = 'retail';

const SearchResultItem = ({
  dimension,
  title,
  options,
  numberOfOptions,
  setInputValue,
  handleClose,
}: {
  dimension?: string;
  title?: string;
  options: Option[];
  numberOfOptions: number;
  setInputValue: React.Dispatch<SetStateAction<string>>;
  handleClose: () => void;
}) => {
  const [showAllDimensionItems, setShowAllDimensionItems] = useState(false);

  const theme = useTheme();
  const dispatch = useDispatch();

  const isShowingAllDimensionItems =
    showAllDimensionItems || numberOfOptions < NUMBER_OF_RESULTS_LIMIT;

  const numberOfItemsToRender = isShowingAllDimensionItems
    ? numberOfOptions
    : Math.min(NUMBER_OF_RESULTS_LIMIT, numberOfOptions);

  const selectDimension = (options: Option[]) => {
    if (!dimension)
      return enqueueSnackbar('No dimension found', { variant: 'error' });

    handleMultipleSelections({
      dispatch,
      dimension: dimension,
      operator: 'equals',
      options: options,
      renderInSidebar: false,
      activePage: ACTIVE_PAGE,
    });
    setInputValue('');
    handleClose();
  };

  const selectDimensionValue = (option: Option) => {
    handleSingleSelections({
      dispatch,
      dimension: option.dimension,
      operator: 'equals',
      option,
      renderInSidebar: false,
      activePage: ACTIVE_PAGE,
    });
    setInputValue('');
    handleClose();
  };

  return (
    <Stack
      sx={{
        '&:hover': {
          backgroundColor: theme.palette.grey[100],
        },
        p: 1,
      }}
      onClick={(e) => {
        e.stopPropagation();
        selectDimension(options);
      }}
    >
      <Typography>{title}</Typography>
      <Stack direction={'row'} flexWrap={'wrap'}>
        {options.map((option, idx) => {
          const isLast = idx === numberOfItemsToRender - 1;
          const stopRendering = idx > numberOfItemsToRender - 1;

          if (stopRendering) return <></>;
          return (
            <React.Fragment key={idx}>
              <Typography
                sx={{
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  selectDimensionValue(option);
                }}
              >
                {option.title}
              </Typography>
              {!isLast && <Typography>,&nbsp;</Typography>}
              {isLast && !isShowingAllDimensionItems && (
                <Typography>...</Typography>
              )}
            </React.Fragment>
          );
        })}
        {!isShowingAllDimensionItems && (
          <Link
            component={Button}
            sx={{
              p: 0,
              ml: 0.5,
              textDecoration: 'underline',
            }}
            variant="inherit"
            onClick={(e) => {
              e.stopPropagation();
              setShowAllDimensionItems(true);
            }}
          >
            Show all
          </Link>
        )}
      </Stack>
    </Stack>
  );
};

export default SearchResultItem;
