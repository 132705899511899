import { type CustomTheme } from '@autone/ui';
import { alpha, experimentalStyled as styled } from '@mui/material/styles';
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme: muiTheme }) => {
  const theme: CustomTheme = muiTheme as unknown as CustomTheme;
  return {
    top: 0,
    left: 0,
    zIndex: 99,
    width: '100%',
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    height: APPBAR_MOBILE,
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    padding: theme.spacing(0, 3),
    boxShadow: theme.customShadows.z8,
    backgroundColor: `${alpha(theme.palette.background.default, 1)}`,
    [theme.breakpoints.up('md')]: {
      height: APPBAR_DESKTOP,
      padding: theme.spacing(0, 5),
    },
  };
});

const SearchbarResultsStyle = styled('div')(({ theme: muiTheme }) => {
  const theme: CustomTheme = muiTheme as unknown as CustomTheme;

  return {
    color: 'black',
    cursor: 'pointer',
    top: APPBAR_MOBILE,
    [theme.breakpoints.up('md')]: {
      top: APPBAR_DESKTOP,
      padding: theme.spacing(0, 0),
    },
    width: '100%',
    position: 'absolute',
    backgroundColor: 'white',
    zIndex: 999999,
    boxShadow: theme.customShadows.z8,
    maxHeight: '350px',
    overflowY: 'scroll',
    padding: '0px',
    borderRadius: '5px',
    left: '0px',
  };
});

export { SearchbarStyle, SearchbarResultsStyle };
