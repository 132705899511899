import { useAutoneTranslation } from '@autone/translations';
import {
  DashboardNavbar,
  DashboardSidebar,
  LanguageSelectionPopover,
  SideBarContent,
  withLanguagePreferenceFeatureFlag,
} from '@autone/ui';
import { experimentalStyled as styled } from '@mui/material/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import { type APP_NAME } from 'rebalancing-app-client/src/constant';

import { filters as filterOptions } from '../config';
import { useFilters } from '../hooks';
import store from '../redux/store';
import { shouldRenderComponent } from '../utils';

import NavbarContent from './navbar/NavbarContent';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 68;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const LanguageSelectionRenderer = withLanguagePreferenceFeatureFlag(
  LanguageSelectionPopover,
  'insights',
  ['en-US', 'en'],
);

// eslint-disable-next-line max-lines-per-function
export default function DashboardLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const [open, setOpen] = useState(false);
  const { activePage } = store.getState().filters;

  const { common } = useAutoneTranslation<typeof APP_NAME>();

  const { analyticsRenderConfig = {} } = useFlags();

  // hide filters depending on customer feature flag
  const customerFilters = filterOptions?.dimensions.map((item) => {
    const { id, renderInSidebar } = item || {};

    // if we are not rendering in sidebar, then don't render it
    if (!renderInSidebar) {
      return item;
    }

    // otherwise, check if we need to render it for that customer
    return {
      ...item,
      renderInSidebar: shouldRenderComponent(analyticsRenderConfig, id),
    };
  });

  useFilters({
    dimensions: customerFilters,
    page: activePage,
  });

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)}>
        <NavbarContent />
      </DashboardNavbar>

      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      >
        <SideBarContent
          renderItems={(isCollapse) => (
            <LanguageSelectionRenderer
              isCollapse={isCollapse}
              labelDict={{
                'en-US': common('language.label.english-us'),
                en: common('language.label.english'),
                it: common('language.label.italian'),
                fr: common('language.label.french'),
              }}
              errorText={common('language.error-text')}
            />
          )}
        />
      </DashboardSidebar>
      <MainStyle>{children}</MainStyle>
    </RootStyle>
  );
}
