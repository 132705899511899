import {
  FilterSidebar,
  INSIGHTS_PATH_APP,
  isKeyInType,
  Navbar,
} from '@autone/ui';
import { Box, Divider, List } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  REMOVE_ALL_CURRENT_FILTERS,
  SELECT_DESELECT_ALL_BY_DIMENSION,
  SELECT_DESELECT_FILTER,
  SELECT_DESELECT_PARAMETER,
} from '../../redux/filters';
import { useAppSelector } from '../../redux/store';
import {
  type Dimension,
  type SelectedVariance,
  type ValueVolume,
} from '../../types';
import { isSelectedVariance, isValueVolume } from '../../types/guards';
import { Mixpanel } from '../../utils/mixpanel';
import { FILTER, SELECTED_METRIC } from '../../utils/mixpanel/eventTypes';
import { Searchbar } from '../searchbar';
// @ts-ignore
import Selections from '../Selections';

// @ts-ignore
import ParameterRadioOptions from './ParameterRadioOptions';

const NavbarContent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { activePage, filters, parameterOptions } = useAppSelector(
    (state) => state.filters,
  );

  const handleRemoveAll = () => {
    dispatch(REMOVE_ALL_CURRENT_FILTERS({ activePage }));
  };

  const handleFilterClickCallback = (
    dimension: Dimension,
    id: ValueVolume | SelectedVariance | string,
    active: boolean,
    type: string,
  ) => {
    if (
      type === 'parameters' &&
      (isSelectedVariance(id) || isValueVolume(id))
    ) {
      dispatch(SELECT_DESELECT_PARAMETER({ dimension, id }));
      Mixpanel.track(SELECTED_METRIC, {
        dimension,
        id,
      });
    } else {
      dispatch(SELECT_DESELECT_FILTER({ dimension, id, active, activePage }));
      Mixpanel.track(FILTER, {
        dimension,
        id,
        active,
        activePage,
      });
    }
  };

  const handleSelectDeselectAllByDimension = ({
    dimension,
    active,
  }: {
    dimension: 'metric' | 'variance';
    active: boolean;
  }) => {
    dispatch(
      SELECT_DESELECT_ALL_BY_DIMENSION({ dimension, active, activePage }),
    );
    Mixpanel.track(FILTER, {
      dimension,
      active,
      activePage,
    });
  };

  const showParameters = pathname === INSIGHTS_PATH_APP.root;
  const showSelectedDateRanges = pathname === INSIGHTS_PATH_APP.explorer;

  const filtersForSidebar =
    activePage && isKeyInType(filters, activePage)
      ? filters?.[activePage]?.filter((item) => item.renderInSidebar)
      : undefined;

  return (
    <Navbar
      contentBeginning={<Searchbar />}
      contentMiddle={
        <Box
          sx={{
            marginLeft: 'auto',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            gap: '10px',
          }}
        >
          <Selections
            showParameters={showParameters}
            showSelectedDateRanges={showSelectedDateRanges}
          />
        </Box>
      }
      contentEnd={
        <>
          <Divider orientation="vertical" flexItem />
          <FilterSidebar
            isOpen={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            initialHiddenOptions={[
              'Product.colorDescription',
              'Product.subDepartmentDescription',
            ]}
            handleFilterClickCallback={handleFilterClickCallback}
            handleSelectDeselectAllByDimension={
              handleSelectDeselectAllByDimension
            }
            handleRemoveAll={handleRemoveAll}
            renderParameters={
              <List sx={{ mt: 1 }}>
                {showParameters && (
                  <ParameterRadioOptions
                    parameterOptions={parameterOptions}
                    handleFilterClickCallback={handleFilterClickCallback}
                  />
                )}
              </List>
            }
            // TODO: type this properly
            filterOptions={{ [activePage]: filtersForSidebar } as any}
            activePage={activePage}
            appName="insights"
            filterSortKey="dimension"
          />
        </>
      }
    />
  );
};

export default NavbarContent;
