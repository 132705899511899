import { type CustomerFilterConfig } from '../../types/filters';

export const getFilterItems = (
  dataKey: string,
  dimensionInfo: CustomerFilterConfig | undefined,
  filteredOptions: { dimension: string; title: string; id: string }[],
) => {
  let filterItem;
  if (
    dataKey === 'Product.product_id_sales' ||
    dataKey === 'Product.product_id_inventory'
  ) {
    filterItem = {
      dimension: 'Product.product_id',
      title: 'Product.product_id',
      operator: 'equals',
      renderInSidebar: dimensionInfo?.renderInSidebar,
      options: filteredOptions,
      key: dataKey,
    };
  } else {
    filterItem = {
      dimension: dimensionInfo?.dimension,
      title: dimensionInfo?.title,
      operator: dimensionInfo?.operator,
      renderInSidebar: dimensionInfo?.renderInSidebar,
      options: filteredOptions,
    };
  }
  return filterItem;
};
