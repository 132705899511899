import { QUERY_LIMIT } from '../../config/consts';
import { type CustomerFilterConfig } from '../../types/filters';

export const getFilterQuery = (dimensions: CustomerFilterConfig[]) => {
  const queries =
    dimensions &&
    dimensions.map((dim) => {
      // splitting product query into two, for sales and inventory
      if (dim.dimension === 'Product.product_id') {
        return [
          {
            dataKey: `${dim.dimension}_sales`,
            query: {
              dimension: [dim.dimension],
              limit: QUERY_LIMIT,
              filters: [
                {
                  member: 'Sales.purchasedSalesQuantity',
                  operator: 'gt',
                  values: ['0'],
                },
              ],
            },
          },
          {
            dataKey: `${dim.dimension}_inventory`,
            query: {
              dimension: [dim.dimension],
              limit: QUERY_LIMIT,
              filters: [
                {
                  member: 'Inventory.currentOnHandQuantity',
                  operator: 'gt',
                  values: ['0'],
                },
              ],
            },
          },
        ];
      }
      if (dim.dimension === 'Product.styleDescription') {
        return {
          dataKey: dim.dimension,
          query: {
            dimension: [dim.dimension],
            limit: QUERY_LIMIT,
            filters: [
              {
                member: 'Sales.totalSalesQuantity',
                operator: 'gt',
                values: ['0'],
              },
            ],
          },
        };
      }

      return {
        dataKey: dim.dimension,
        query: {
          dimension: [dim.dimension],
          limit: QUERY_LIMIT,
        },
      };
    });
  return queries?.flat();
};
